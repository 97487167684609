<template>
    <div class="add-box">
        <i-header :title="title" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <i-field v-model="message" rows="4" autosize type="textarea" placeholder="请填写情况说明" />
            <i-button :loading="loading" @click="submit" :disabled="loading" loading-text="提交" block round type="primary" size="normal"> 提交 </i-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    components: {},
    data() {
        return {
            title: "情况说明",
            loading: false,
            message: "",
        };
    },
    created() {
        this.init();
        //初始化

        this.message = this.$route.query.OldData
    },
    methods: {
        async init() {},
        toBack() {
            this.$router.back();
        },
        submit() {
            if (this.message) {
                console.log("====提交会议总结", this.message);
                this.loading = true;

                this.$service.saveEventSummary({
                    miceId:this.$route.query.miceId,
                    situationDescription:this.message
                }).then((res) => {
                    this.loading = false;
                    if (res.success) {
                         this.$router.back();
                    } else {
                        this.$itoast.fail("订单错误,请联系管理员！");
                    }
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 0.25rem;
        box-sizing: border-box;
        /deep/ .van-cell {
            background: #F5F5F5;
            border-radius: 0.04rem;
            margin-bottom: 0.3rem;
        }
        /deep/ .van-button__text {
            font-size: 0.16rem;
        }
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>
