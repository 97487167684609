var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-box" },
    [
      _c("i-header", {
        attrs: { title: _vm.title },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _c("i-field", {
            attrs: {
              rows: "4",
              autosize: "",
              type: "textarea",
              placeholder: "请填写情况说明",
            },
            model: {
              value: _vm.message,
              callback: function ($$v) {
                _vm.message = $$v
              },
              expression: "message",
            },
          }),
          _c(
            "i-button",
            {
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                "loading-text": "提交",
                block: "",
                round: "",
                type: "primary",
                size: "normal",
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" 提交 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }